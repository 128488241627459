import React, {createContext, useReducer} from 'react';

const initialState = {
    isSidebarOpened: false,
    premiumLocationFilter : 'all',
    premiumTimePeriodFilter : 12,
    statementModal : {
        isOpen : false,
        uln : '',
        id: ''
    }
};
const store = createContext(initialState);
const {Provider} = store;

function reducer(state, action) {
    switch (action.type) {
        case 'toggle_sidebar':
            return {
                ...state,
                isSidebarOpened : !state.isSidebarOpened
            }
        case 'premium_location_filter_change':
            return {
                ...state,
                premiumLocationFilter : action.payload
            }
        case 'premium_time_period_filter_change':
            return {
                ...state,
                premiumTimePeriodFilter : action.payload
            }
        case 'toggle_statement_modal':
            return {
                ...state,
                statementModal : {
                    isOpen : action.payload.isOpen,
                    uln : action.payload.uln,
                    id: action.payload.id
                }
            }
        default:
            throw new Error();
    }
}

const StateProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return <Provider value={{state, dispatch}}>{children}</Provider>;
};

export {store, StateProvider}
