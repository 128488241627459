require('./bootstrap')

import React, { Suspense } from 'react'
import { render } from 'react-dom'
import { createInertiaApp } from '@inertiajs/inertia-react'
import { InertiaProgress } from '@inertiajs/progress'
import { Inertia } from '@inertiajs/inertia'

const appName = window.document.getElementsByTagName('title')[0]?.innerText ||
    'Laravel'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

// Language package
import './i18n';

// Store
import { StateProvider } from '@/store'

var isDevelopment = process.env.NODE_ENV === 'development'

if (!isDevelopment) {
    Sentry.init({
        dsn: 'https://4c7e91b5109a4788b2a2d895290dbe98@o424104.ingest.sentry.io/5823603',
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    })
}

if (process.env.NODE_ENV === 'production') {
    Inertia.on("navigate", (event) => {
        gtag("js", new Date());
        gtag("config", "UA-236528497-1");
    });
}

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => import(`./Pages/${name}`),
    setup ({ el, App, props }) {
        // Wrapping root App component instead of wrap for each component
        return render(<Suspense fallback={null}><StateProvider><App {...props} /></StateProvider></Suspense>, el)
    },
})

InertiaProgress.init({ color: '#4B5563' })
