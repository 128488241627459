import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Required for proper copy of JSON files with translations for Webpack 5
import en from '../i18n/portal/en.json';
import fr from '../i18n/portal/fr.json';
import es from '../i18n/portal/es.json';

import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
i18n
    .use(resourcesToBackend((language, namespace, callback) => {
        import(`../i18n/portal/${language}.json`)
            .then((resources) => {
                callback(null, resources)
            })
            .catch((error) => {
                callback(error, null)
            })
    }))
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        debug: false,
        ns : 'portal'
    });


export default i18n;
